import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/main.css";
import productData from "../Datas/productData";
import { Link } from "react-router-dom";

function Main() {
  const handleNavigate = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        <div className="transparent-div">
          <img alt="" src="/head/main.jpg" />
        </div>
        <div className="mainBox1">
          <div className="container-fluid">
            <div className="top-container">
              <h1>Featured collection</h1>
              <div className="product-grid">
                {productData
                  .filter((plastic) => plastic.id >= 1 && plastic.id <= 6)
                  .map((brand) => (
                    <div className="product-category" key={brand.id}>
                      <div className="image-container">
                        <Link
                          to={`/shopDetails/${brand.id}`}
                          onClick={handleNavigate}
                        >
                          <img alt="" src={brand.images[0]} />
                        </Link>
                        <div className="card__info card__info--aligned">
                          <Link
                            to={`/shopDetails/${brand.id}`}
                            onClick={handleNavigate}
                          >
                            <h3>{brand.name}</h3>
                            <p>
                              Form&nbsp;
                              <img
                                src="https://img.icons8.com/emoji/48/us-outlying-islands-emoji.png"
                                alt="us-outlying-islands-emoji"
                                style={{ width: "22px", height: "22px" }}
                              />
                              &nbsp; ${brand.price[0]}
                            </p>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className="top-container">
              <h1>Virtual Staging - Photos & 360° Tours</h1>
              <div className="product-grid">
                {productData
                  .filter((plastic) => plastic.id >= 7 && plastic.id <= 8)
                  .map((brand) => (
                    <div className="product-category" key={brand.id}>
                      <div className="image-container">
                        <Link
                          to={`/shopDetails/${brand.id}`}
                          onClick={handleNavigate}
                        >
                          <img alt="" src={brand.images[0]} />
                        </Link>
                        <div className="card__info card__info--aligned">
                          <Link
                            to={`/shopDetails/${brand.id}`}
                            onClick={handleNavigate}
                          >
                            <h3>{brand.name}</h3>
                            <p>
                              Form&nbsp;
                              <img
                                src="https://img.icons8.com/emoji/48/us-outlying-islands-emoji.png"
                                alt="us-outlying-islands-emoji"
                                style={{ width: "22px", height: "22px" }}
                              />
                              &nbsp; ${brand.price[0]}
                            </p>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className="top-container">
              <h1>Our Combined Service Packages</h1>
              <div className="product-grid">
                {productData
                  .filter((plastic) => plastic.id >= 9 && plastic.id <= 11)
                  .map((brand) => (
                    <div className="product-category" key={brand.id}>
                      <div className="image-container">
                        <Link
                          to={`/shopDetails/${brand.id}`}
                          onClick={handleNavigate}
                        >
                          <img alt="" src={brand.images[0]} />
                        </Link>
                        <div className="card__info card__info--aligned">
                          <Link
                            to={`/shopDetails/${brand.id}`}
                            onClick={handleNavigate}
                          >
                            <h3>{brand.name}</h3>
                            <p>
                              Form&nbsp;
                              <img
                                src="https://img.icons8.com/emoji/48/us-outlying-islands-emoji.png"
                                alt="us-outlying-islands-emoji"
                                style={{ width: "22px", height: "22px" }}
                              />
                              &nbsp; ${brand.price[0]}
                            </p>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className="shopity">
          <div>
            <h2>OUR SERVICES</h2>
            <p>
              Photography | Floor Plans | 360° Virtual Tours | Architectural
              Visualisation | Virtual Staging | Lease Plans | 3D Floor Plans |
              Aerial Drone Video &amp; Stills
            </p>
          </div>
        </div>
        <div className="image-bar--medium">
          <div className="image-bar__flex image-bar__3-column">
            <img alt="" src="/head/main1.jpg" />
            <div className="center__txt">
              <img
                width="32"
                height="32"
                src="https://img.icons8.com/windows/32/dfa160/ok.png"
                alt="ok"
              />
              <h3 className="h2 text-title image-bar__caption">
                STUNNING PHOTOGRAPHY
              </h3>
            </div>
          </div>
          <div className="image-bar__flex image-bar__3-column">
            <img alt="" src="/head/main2.jpg" />
            <div className="center__txt">
              <img
                width="32"
                height="32"
                src="https://img.icons8.com/windows/32/dfa160/ok.png"
                alt="ok"
              />
              <h3
                className="h2 text-title image-bar__caption"
                style={{ color: "#000" }}
              >
                FLOOR PLANS
              </h3>
            </div>
          </div>
          <div className="image-bar__flex image-bar__3-column">
            <img alt="" src="/head/main3.jpg" className="bac__img" />
            <div className="center__txt">
              <img
                src="https://img.icons8.com/windows/32/dfa160/ok.png"
                alt="ok"
              />
              <h3 className="h2 text-title image-bar__caption">
                STUNNING PHOTOGRAPHY
              </h3>
            </div>
          </div>
        </div>

        <div className="highlight--align-middle">
          <div className="align-twelve">
            {productData
              .filter((plastic) => plastic.id >= 12 && plastic.id <= 12)
              .map((brand) => (
                <>
                  <Link
                    to={`/shopDetails/${brand.id}`}
                    onClick={handleNavigate}
                  >
                    <img alt="" src={brand.images[0]} />
                  </Link>
                  <Link
                    to={`/shopDetails/${brand.id}`}
                    onClick={handleNavigate}
                    style={{ marginLeft: "10%", marginTop: "-10%" }}
                  >
                    <h3>{brand.name}</h3>
                    <p>
                      We employ only the very best photographers that ensure
                      your marketing material is of the very best quality each
                      and every time.
                    </p>
                  </Link>
                </>
              ))}
          </div>
        </div>

        <div className="img__txt">
          <div className="img__txt__left">
            <h2
              className="feature-row__heading h3 separator has-animated"
              data-animate=""
            >
              High-End Property Photography as Standard
            </h2>
            <p>Check out some of stuning photography </p>
          </div>
          <img alt="" src="/head/main4.jpg" />
        </div>

        <div className="img__txt">
          <img alt="" src="/head/main5.jpg" />
          <img alt="" src="/head/main6.jpg" />
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Main;
