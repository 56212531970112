import React, { useContext } from "react";
import { CartContext } from "../Component/CartContext";
import productData from "../Datas/productData";
import Header from "./Header";
import Footer from "./Footer";
import "../css/cartpage.css";
import { useNavigate, Link } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

const StyledTableContainer = styled(TableContainer)({
  minWidth: 650,
});

const StyledImage = styled("img")({
  width: 112,
  height: 124,
  objectFit: "cover",
});

const StyledTableCell = styled(TableCell)({
  display: "flex",
  alignItems: "center",
  verticalAlign: "middle",
  fontSize: "16px",
  paddingTop: "33%",
  borderBottom: "none",
});

const CartPage = () => {
  const { cartItems, removeFromCart, updateQuantity } = useContext(CartContext);
  const navigate = useNavigate();

  const getProductById = (id) => {
    return productData.find((product) => product.id === id);
  };

  const toCheckOut = () => {
    if (cartItems.length === 0) {
      alert("Please add items to your cart before proceeding to checkout.");
      return;
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    navigate("/checkout");
  };
  const calculateTotal = () => {
    return cartItems.reduce((total, item) => {
      const product = getProductById(item.id);
      if (!product) return total;

      const index = product.number.indexOf(item.number);
      const price = parseFloat(product.price[index], 10);
      return total + price * item.quantity;
    }, 0);
  };

  const calculateItemTotal = (itemId, number, quantity) => {
    const product = getProductById(itemId);
    if (!product) return 0;

    const index = product.number.indexOf(number);
    if (index === -1) return 0;

    const price = parseFloat(product.price[index], 10);
    return price * quantity;
  };

  const handleInputChange = (id, event, number, price) => {
    const newQuantity = parseInt(event.target.value, 10);
    if (!isNaN(newQuantity) && newQuantity >= 0) {
      updateQuantity(id, newQuantity, number, price);
    }
  };

  return (
    <>
      <div className="main-container">
        <Header />
        <div className="cart-page">
          <div className="basket-title">
            <h1>Shopping Basket</h1>
          </div>
          <div className="cart-total">
            <div className="cart-items">
              {cartItems.length === 0 ? (
                <h3 style={{ color: "black", textAlign: "center" }}>
                  Your Cart is Empty!
                </h3>
              ) : (
                <StyledTableContainer component={Paper}>
                  <Table aria-label="shopping cart table">
                    <TableBody>
                      {cartItems.map((item, index) => {
                        const product = getProductById(item.id);
                        return (
                          <TableRow key={index}>
                            <StyledTableCell component="th" scope="row">
                              <StyledImage
                                src={product.images[0]}
                                alt="Product"
                                style={{
                                  width: "88px",
                                  height: "88px",
                                  borderRadius: "50%",
                                }}
                              />
                            </StyledTableCell>
                            <TableCell
                              align="left"
                              style={{
                                verticalAlign: "middle",
                                borderRight: "1px dotted #ccc",
                              }}
                            >
                              <h2
                                style={{ lineHeight: "1.5", fontSize: "20px" }}
                              >
                                {product.name}
                              </h2>
                              <p style={{ color: "#4a4a4a" }}>{item.number}</p>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "left",
                                }}
                              >
                                <span
                                  className="currency-converter-amount-box"
                                  style={{ fontWeight: "600" }}
                                >
                                  <img
                                    src="https://img.icons8.com/emoji/48/us-outlying-islands-emoji.png"
                                    alt="us-outlying-islands-emoji"
                                    style={{ width: "22px", height: "22px" }}
                                  />
                                  &nbsp; ${item.price}
                                </span>
                              </div>
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                verticalAlign: "middle",
                              }}
                            >
                              <input
                                type="number"
                                value={item.quantity || 1}
                                className="cartInput"
                                onChange={(e) =>
                                  handleInputChange(
                                    item.id,
                                    e,
                                    item.number,
                                    item.price
                                  )
                                }
                                min="0"
                              />
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{ verticalAlign: "middle" }}
                            >
                              $
                              {calculateItemTotal(
                                item.id,
                                item.number,
                                item.quantity
                              ).toFixed(2)}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                verticalAlign: "middle",
                                borderRight: "2px solid rgb(243, 243, 243)",
                              }}
                            >
                              <Link
                                to="/cartpage"
                                onClick={() =>
                                  removeFromCart(item.id, item.number)
                                }
                                style={{
                                  cursor: "context-menu",
                                  textDecoration: "underline",
                                  fontFamily: "Libre Baskerville",
                                  fontSize: "13px",
                                  fontWeight: "600",
                                  color: "#4a4a4a",
                                }}
                              >
                                Remove
                              </Link>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </StyledTableContainer>
              )}
              {cartItems.length > 0 && (
                <div className="cart-summary">
                  <div className="rightSum">
                    <table>
                      <tbody>
                        <tr>
                          <td className="td-left">Sub-total:</td>
                          <td>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "left",
                              }}
                            >
                              <span
                                className="currency-converter-amount-box"
                                style={{ fontWeight: "600" }}
                              >
                                <img
                                  src="https://img.icons8.com/emoji/48/us-outlying-islands-emoji.png"
                                  alt="us-outlying-islands-emoji"
                                  style={{ width: "22px", height: "22px" }}
                                />
                                &nbsp; ${calculateTotal().toFixed(2)}
                              </span>
                            </div>
                          </td>
                        </tr>
                        <tr
                          style={{
                            border: "none",
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "-9%",
                            color: "rgb(74, 74, 74)",
                          }}
                        >
                          <p>Taxes and shipping calculated at checkout</p>
                        </tr>
                      </tbody>
                    </table>
                    <div className="btn___box">
                      <button className="checkout-button" onClick={toCheckOut}>
                        Checkout
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default CartPage;
