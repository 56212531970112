import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/contactus.css";

function Privacy() {
  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        <div className="mainBox1" style={{ padding: "0 28%" }}>
          <div className="container-fluid">
            <div className="signpost1">
              <h1 style={{ textAlign: "center", fontSize: "41px" }}>
                Privacy policy
              </h1>
              <p>PRIVACY STATEMENT</p>
              <p>----</p>
              <p>
                <strong>
                  SECTION 1 - WHAT DO WE DO WITH YOUR INFORMATION?
                </strong>
              </p>
              <p>
                When you purchase something from our store, as part of the
                buying and selling process, we collect the personal information
                you give us such as your name, address and email address.
                <br />
                When you browse our store, we also automatically receive your
                computer’s internet protocol (IP) address in order to provide us
                with information that helps us learn about your browser and
                operating system. Email marketing (if applicable): With your
                permission, we may send you emails about our store, new products
                and other updates.
              </p>
              <p>
                <strong>SECTION 2 - CONSENT</strong>
              </p>
              <p>
                How do you get my consent?
                <br />
                When you provide us with personal information to complete a
                transaction, verify your credit card, place an order, arrange
                for a delivery or return a purchase, we imply that you consent
                to our collecting it and using it for that specific reason only.
                <br />
                If we ask for your personal information for a secondary reason,
                like marketing, we will either ask you directly for your
                expressed consent, or provide you with an opportunity to say no.
              </p>
              <p>How do I withdraw my consent?</p>
              <p>
                If after you opt-in, you change your mind, you may withdraw your
                consent for us to contact you, for the continued collection, use
                or disclosure of your information, at any time, by contacting us
                at number@soliress.xyz or mailing us at:
                <br />
                number@soliress.xyz
                <br />1 Towerfield Close, Southend-on-Sea, Essex, SS3 9QP
              </p>
              <p>
                <strong>SECTION 3 - DISCLOSURE</strong>
              </p>
              <p>
                We may disclose your personal information if we are required by
                law to do so or if you violate our Terms of Service.
              </p>
              <p>
                <strong>SECTION 4 - SHOPIFY</strong>
              </p>
              <p>
                Our store is hosted by Shopify Inc. They provide us with the
                online e-commerce platform that allows us to sell our products
                and services to you.
                <br />
                Your data is stored in Shopify’s data storage, databases and the
                general Shopify application. They store your data on a secure
                server behind a firewall.
              </p>
              <p>
                Payment:
                <br />
                If you choose a direct payment gateway to complete your
                purchase, then Shopify stores your credit card data. It is
                encrypted through the Payment Card Industry Data Security
                Standard (PCI-DSS). Your purchase transaction data is stored
                only as long as is necessary to complete your purchase
                transaction. After that is complete, your purchase transaction
                information is deleted.
                <br />
                All direct payment gateways adhere to the standards set by
                PCI-DSS as managed by the PCI Security Standards Council, which
                is a joint effort of brands like Visa, MasterCard, American
                Express and Discover.
                <br />
                PCI-DSS requirements help ensure the secure handling of credit
                card information by our store and its service providers.
                <br />
                For more insight, you may also want to read Shopify’s Terms of
                Service here or Privacy Statement here.
              </p>
              <p>
                <strong>SECTION 5 - THIRD-PARTY SERVICES</strong>
              </p>
              <p>
                In general, the third-party providers used by us will only
                collect, use and disclose your information to the extent
                necessary to allow them to perform the services they provide to
                us.
                <br />
                However, certain third-party service providers, such as payment
                gateways and other payment transaction processors, have their
                own privacy policies in respect to the information we are
                required to provide to them for your purchase-related
                transactions.
                <br />
                For these providers, we recommend that you read their privacy
                policies so you can understand the manner in which your personal
                information will be handled by these providers.
                <br />
                In particular, remember that certain providers may be located in
                or have facilities that are located a different jurisdiction
                than either you or us. So if you elect to proceed with a
                transaction that involves the services of a third-party service
                provider, then your information may become subject to the laws
                of the jurisdiction(s) in which that service provider or its
                facilities are located.
                <br />
                As an example, if you are located in Canada and your transaction
                is processed by a payment gateway located in the United States,
                then your personal information used in completing that
                transaction may be subject to disclosure under United States
                legislation, including the Patriot Act.
                <br />
                Once you leave our store’s website or are redirected to a
                third-party website or application, you are no longer governed
                by this Privacy Policy or our website’s Terms of Service.
              </p>
              <p>
                Links <br />
                When you click on links on our store, they may direct you away
                from our site. We are not responsible for the privacy practices
                of other sites and encourage you to read their privacy
                statements. <br />
                Google analytics: <br />
                Our store uses Google Analytics to help us learn about who
                visits our site and what pages are being looked at
              </p>
              <p>
                <strong>SECTION 6 - SECURITY</strong>
              </p>
              <p>
                To protect your personal information, we take reasonable
                precautions and follow industry best practices to make sure it
                is not inappropriately lost, misused, accessed, disclosed,
                altered or destroyed.
                <br />
                If you provide us with your credit card information, the
                information is encrypted using secure socket layer technology
                (SSL) and stored with a AES-256 encryption. Although no method
                of transmission over the Internet or electronic storage is 100%
                secure, we follow all PCI-DSS requirements and implement
                additional generally accepted industry standards.
              </p>
              <p>
                <strong>SECTION 7 - COOKIES</strong>
              </p>
              <p>
                Here is a list of cookies that we use. We’ve listed them here so
                you that you can choose if you want to opt-out of cookies or
                not.
                <br />
                _session_id, unique token, sessional, Allows Shopify to store
                information about your session (referrer, landing page, etc).
                <br />
                _shopify_visit, no data held, Persistent for 30 minutes from the
                last visit, Used by our website provider’s internal stats
                tracker to record the number of visits
                <br />
                _shopify_uniq, no data held, expires midnight (relative to the
                visitor) of the next day, Counts the number of visits to a store
                by a single customer.
                <br />
                cart, unique token, persistent for 2 weeks, Stores information
                about the contents of your cart.
                <br />
                _secure_session_id, unique token, sessional
                <br />
                storefront_digest, unique token, indefinite If the shop has a
                password, this is used to determine if the current visitor has
                access.
                <br />
                PREF, persistent for a very short period, Set by Google and
                tracks who visits the store and from wheren
              </p>
              <p>
                <strong>SECTION 8 - AGE OF CONSENT</strong>
              </p>
              <p>
                By using this site, you represent that you are at least the age
                of majority in your state or province of residence, or that you
                are the age of majority in your state or province of residence
                and you have given us your consent to allow any of your minor
                dependents to use this site.
              </p>
              <p>
                <strong>SECTION 9 - CHANGES TO THIS PRIVACY POLICY</strong>
              </p>
              <p>
                We reserve the right to modify this privacy policy at any time,
                so please review it frequently. Changes and clarifications will
                take effect immediately upon their posting on the website. If we
                make material changes to this policy, we will notify you here
                that it has been updated, so that you are aware of what
                information we collect, how we use it, and under what
                circumstances, if any, we use and/or disclose it.
                <br />
                If our store is acquired or merged with another company, your
                information may be transferred to the new owners so that we may
                continue to sell products to you.
              </p>
              <p>
                <strong>QUESTIONS AND CONTACT INFORMATION</strong>
              </p>
              <p>
                If you would like to: access, correct, amend or delete any
                personal information we have about you, register a complaint, or
                simply want more information contact our Privacy Compliance
                Officer at number@soliress.xyz or by mail at
                <br />
                Photoplan Shop
                <br />
                [Re: Privacy Compliance Officer]
                <br />
                [1 Towerfield Close, Southend-on-Sea, Essex, SS3 9QP]
                <br />
                ----
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Privacy;
