import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/contactus.css";

function Refund() {
  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        <div className="mainBox1" style={{ padding: "0 28%" }}>
          <div className="container-fluid">
            <div className="signpost1">
              <h1 style={{ textAlign: "center", fontSize: "41px" }}>
                Refund policy
              </h1>
              <p>Refund Policy</p>
              <p>
                Please note that we do not offer refunds after we have visited a
                property. Once our representative has visited the site, the
                service is considered delivered and non-refundable.
              </p>
              <p>
                For shop orders, we will initially send unedited images. These
                images must be chosen before the finished imagery is fully
                delivered.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Refund;
