import "../css/header.css";
import React from "react";
import { Link } from "react-router-dom";

function Header() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="header-container">
      <div className="header">
        <div className="header__inner">
          <nav className="navigation">
            <ul className=" main-nav">
              <li className="menu-item">
                <Link to="/" className="homeToA" onClick={toTop}>
                  Home
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/about" className="homeToA" onClick={toTop}>
                  About Us
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/privacy" className="homeToA" onClick={toTop}>
                  Privacy
                </Link>
              </li>
            </ul>
          </nav>
          <div className="header__inner__left">
            <div className="header__nav__logo">
              <Link to="/">
                <img alt="" src="/head/head1.png" />
              </Link>
            </div>
          </div>
          <div className="header__inner__right">
            <Link to="/cartpage">
              <img
                width="28"
                height="28"
                src="https://img.icons8.com/material-outlined/24/dfa160/shopping-cart--v1.png"
                alt="shopping-cart--v1"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
