const productData = [
  {
    id: 1,
    name: "Property Photography | Residential Property",
    details: [
      "At the heart of our offerings is our distinguished Property Photography service. Our deep-rooted passion for capturing the essence of properties, combined with our technical expertise and industry insights, has positioned us as a frontrunner in Property Photography.",
      "We are known for our adaptable approach, tailoring our services to meet your specific requirements, from a single, striking exterior shot to an extensive, full-day photoshoot of more significant properties like hotels. Our commitment to innovation drives us to continually explore emerging technologies and advanced techniques, ensuring we deliver top-tier photographic solutions.",
      "For more information, visit our website: Photoplan Property Photography",
      "Please Note:For properties located in remote areas, additional travel expenses may apply. We recommend contacting us before booking, as our photographers are predominantly based in urban areas. This ensures clarity on any extra travel costs.",
    ],
    label: "Number of Images",
    number: [
      "10 Photos",
      "15 Photos",
      "20 Photos",
      "30 Photos",
      "40 Photos",
      "50 Photos",
    ],
    price: ["133.00", "167.00", "200.00", "267.00", "334.00", "401.00"],
    images: [
      "/photoplan/1001.webp",
      "/photoplan/1001a.jpg",
      "/photoplan/1001b.jpg",
      "/photoplan/1001c.jpg",
      "/photoplan/1001d.jpg",
      "/photoplan/1001e.jpg",
      "/photoplan/1001f.jpg",
      "/photoplan/1001g.jpg",
      "/photoplan/1001h.jpg",
      "/photoplan/1001i.webp",
      "/photoplan/1001j.jpg",
      "/photoplan/1001k.jpg",
    ],
  },
  {
    id: 2,
    name: "Commercial Property Photography",
    details: [
      "Our Property Photography service specialises in capturing the essence and unique characteristics of various commercial properties. Our passion for showcasing properties in their best light, combined with our technical expertise and deep understanding of the commercial real estate market, positions us as leaders in Property Photography.",
      "We tailor our services to meet your specific needs, offering everything from a single, impactful exterior photograph to comprehensive, all-day photoshoots for more significant commercial properties. Our dedication to innovation means we're always exploring the latest technologies and techniques, ensuring we deliver the highest standard of photographic solutions.",
      "We specialise in photographing a wide range of commercial spaces, including:Hotels,Office Buildings,Retail Spaces,Restaurants and Cafés,Industrial Properties,Leisure Facilities,Educational Institutions",
      "Additional Information:Should your commercial property be located in a remote area, please be aware there may be additional travel costs. We recommend contacting us before booking, as our photographers are primarily based in urban areas. This will help clarify any potential extra travel expenses.",
    ],
    label: "Number of Images",
    number: [
      "10 Photos",
      "20 Photos",
      "30 Photos",
      "40 Photos",
      "50 Photos",
      "60 Photos",
      "70 Photos",
      "80 Photos",
      "90 Photos",
      "100 Photos",
    ],
    price: [
      "267.00",
      "401.00",
      "535.00",
      "669.00",
      "803.00",
      "937.00",
      "1070.00",
      "1204.00",
      "1338.00",
      "1472.00",
    ],
    images: [
      "/photoplan/1002.webp",
      "/photoplan/1002a.jpg",
      "/photoplan/1002b.jpg",
      "/photoplan/1002c.jpg",
      "/photoplan/1002d.jpg",
      "/photoplan/1002e.jpg",
      "/photoplan/1002f.jpg",
    ],
  },
  {
    id: 3,
    name: "Property Photography & Floor Plans",
    details: [
      "Capturing Excellence in Every Frame: Property Photography Redefined",
      "At the core of our expertise lies Property Photography, a service we're immensely passionate about. Our unwavering commitment to exceptional quality, combined with our deep understanding of the industry, has established us as a premier choice in the Property Photography sector.",
      "We take great pride in our ability to tailor our Property Photography packages to your specific requirements. Whether you need a stunning exterior shot or a comprehensive day-long photoshoot for a hotel, our approach is flexible and client-centered. Constantly pushing the boundaries, we are on a continuous quest for the latest technology and advanced techniques to ensure we deliver the finest photographic services possible.",
      "Services We Offer:Property Photography & Floor PlansSpecialised Floor Plan Services",
      "Important Information to Note:Additional travel costs may apply for properties in remote areas, as most of our photographers are based in urban centers. Please contact us before booking to discuss any potential extra travel expenses, ",
    ],
    label: "Photos",
    number: [
      "10 Photos",
      "15 Photos",
      "20 Photos",
      "30 Photos",
      "40 Photos",
      "50 Photos",
    ],
    price: ["200.00", "267.00", "401.00", "535.00", "535.00", "669.00"],
    images: [
      "/photoplan/1003.webp",
      "/photoplan/1003a.jpg",
      "/photoplan/1003b.jpg",
      "/photoplan/1003c.jpg",
      "/photoplan/1003d.jpg",
      "/photoplan/1003e.jpg",
      "/photoplan/1003f.jpg",
      "/photoplan/1003g.jpg",
      "/photoplan/1003h.jpg",
      "/photoplan/1003i.jpg",
      "/photoplan/1003j.jpg",
      "/photoplan/1003k.jpg",
      "/photoplan/1003l.jpg",
      "/photoplan/1003m.jpg",
    ],
  },
  {
    id: 4,
    name: "High End Real Estate Property Video",
    details: [
      "Tailored for High-End Property Marketing: Elevate your luxury property's appeal with sophisticated video marketing strategies.",
      "Capture the Essence with Premium Quality Videos: Showcase the unparalleled elegance and detail of your high-end property through cinematic-quality videos.",
      "Optimal for Social Media Engagement: Enhance your digital presence on platforms like YouTube and Facebook with videos that captivate and engage potential buyers.",
      "Seamless Integration Across Online Platforms: Easily share your exquisite property videos on all major property portals using a straightforward URL link, broadening your audience reach.",
      "Efficient Production Without Compromising Quality: Benefit from cost-effective solutions in creating high-end real estate videos that deliver value and impact.",
      "Significantly Boost Your Listings: Stand out in the competitive real estate market with visually stunning videos that draw attention and drive results.",
      "Exceptional Video Quality That Speaks Volumes: Produce breathtaking visuals that highlight the unique features and luxury of your property, offering viewers a compelling experience.",
      "Discover how we bring luxury properties to life through high-end real estate videos by visiting our YouTube Channel. Explore our portfolio for inspiration and subscribe to stay updated with the latest trends and techniques in property video marketing.",
    ],
    label: "Shoot Time + Edit",
    number: [
      "One Hour Shoot and One Hour Edit",
      "Two Hour Shoot and Two Hour Edit (Half a Day)",
      "Half Day and Half Day Edit (One Full Day)",
      "Full Day and Full Day Edit  (Two Full Days)",
    ],
    price: ["401.00", "669.00", "1338.00", "2677.00"],
    images: [
      "/photoplan/1004.webp",
      "/photoplan/1004a.jpg",
      "/photoplan/1004b.jpg",
      "/photoplan/1004c.jpg",
      "/photoplan/1004d.jpg",
    ],
  },
  {
    id: 5,
    name: "Matterport 3D VR Scanning - Residential Properties",
    details: [
      "Experience your home like never before with a Matterport 3D Scan. Impress prospective buyers with a 3D Showcase—an immersive online experience that provides a genuine sense of your property before they ever visit in person. Our team utilizes Matterport's cutting-edge technology to enhance your listing, attract more qualified open house attendees, and filter out less interested buyers.",
      "Your virtual Matterport Space will be ready to explore within hours and easily accessible through any browser.",
      "Inside View: Navigate the property like a video game with Inside View.",
      "Dollhouse View: The proprietary Dollhouse View demonstrates how the entire property fits together.",
      "Floor Plan View: Get a quick understanding of the layout with a top-down Floor Plan View.",
      "The tour link is valid for 12 Months. We charge an annual fee starting from £99 for any additional year. If you require longer, you can buy longer here. The other alternative is to set up your account on the Matterport platform, and we can transfer the tour to your account. ",
      "For quotes on properties larger than 5,000 sq ft, please contact info@photoplan.co.uk.",
    ],
    label: "Size of Property",
    number: [
      "Up to 1000 sq ft",
      "Up to 3000 sq ft",
      "Up to 3000 sq ft",
      "Up to 3000 sq ft",
    ],
    price: ["535.00", "669.00", "803.00", "937.00", "1070.00"],
    images: [
      "/photoplan/1005.webp",
      "/photoplan/1005a.jpg",
      "/photoplan/1005b.jpg",
      "/photoplan/1005c.jpg",
      "/photoplan/1005d.jpg",
      "/photoplan/1005e.jpg",
      "/photoplan/1005f.png",
      "/photoplan/1005g.png",
      "/photoplan/1005h.png",
      "/photoplan/1005i.png",
    ],
  },
  {
    id: 6,
    name: "Matterport 3D VR Scanning - Commercial Properties",
    details: [
      "Discover your commercial property like never before through a Matterport 3D Scan. Captivate prospective buyers with a 3D Showcase—an immersive online experience that offers an accurate representation of your property before they set foot on-site. ",
      "Your virtual Matterport Space will be ready for exploration within hours and is easily accessible via any browser.",
      "Inside View: Traverse the property as if you were in a video game with Inside View. Dollhouse View: Our unique Dollhouse View illustrates how the entire property connects seamlessly. Floor Plan View: Gain an instant understanding of the layout with a bird's-eye Floor Plan View. For quotes on properties larger than 5,000 sq ft, please contact info@photoplan.co.uk.",
      "IMPORTANT NOTE:Extra travel costs may be incurred for properties in remote geographical areas. If your property is situated in a secluded location, it is unlikely that we have a photographer living in close proximity, as the majority of our photographers reside in urban areas. Please contact us before booking to ensure you are comfortable with any potential additional travel expenses.",
    ],
    label: "Type of Property",
    number: ["Commercial"],
    price: ["803.00"],
    images: [
      "/photoplan/1006.webp",
      "/photoplan/1006a.png",
      "/photoplan/1006b.png",
      "/photoplan/1006c.png",
      "/photoplan/1006d.jpg",
      "/photoplan/1006e.png",
    ],
  },
  // Metal pens
  {
    id: 7,
    name: "Essential Virtual Staging - Still Image for Living Rooms",
    details: [
      "Elevate your vacant property to new heights of appeal with our cost-effective virtual furnishing solutions.",
      "We offer three distinct furniture style collections: Ford, Harrison, and Boden. Once you select the design that resonates with your space, we will meticulously craft the images to reflect the chosen theme, ensuring each room exudes warmth and sophistication.",
      "Our virtual staging services provide stunningly realistic still images of furniture arrangements, transforming empty interiors into captivating, homely spaces. This innovative approach not only adds charm to your property but also attracts potential buyers by presenting a polished, furnished look without the high expense of physical staging.",
      "To proceed, simply decide how many rooms you wish to virtually stage and indicate this in your order, and we will handle the rest, delivering a visually enriched property that stands out in the market.",
    ],
    label: "Style",
    number: [
      "Boden - Living Room",
      "Ford - Living Room",
      "Harrison - Living Room",
    ],
    price: ["30.00", "30.00", "30.00"],
    images: ["/photoplan/1007.webp"],
  },
  {
    id: 8,
    name: "Premium Virtual Staging - Still Images",
    details: [
      "Elevate Your Property Listings with Our Premium Virtual Staging Services",
      "Why Virtual Staging?",
      "In today's competitive real estate market, first impressions are everything. Our Virtual Staging services empower you to showcase properties in their best light, enticing potential buyers to take the next step. Here's why you should choose virtual over traditional staging:",
      "🔹 Affordability: Say goodbye to the hefty costs associated with physical staging. Our virtual solutions are budget-friendly without compromising on quality.",
      "🔹 Speed: In a fast-paced market, quick listing is key. Our Virtual Staging gets your property market-ready in record time.",
      "🔹 Customisation: Tailor your staging to match diverse lifestyles and tastes, increasing the property's appeal to a wider audience.",
      "The Process:",
      "Select the number of images you require: Choose from our range of affordable staging packages to fit your needs and budget",
      "Send us Your Photos: Simply send us high-resolution images of the rooms you want to stage with the furniture styles. Using https://photoplan.wetransfer.com/",
      "We Start Designing: We will start work on the images as soon we we have received the order via the shop. ",
      "Approve the Design: We'll send you a preview of the staged rooms for your approval. Need tweaks? We're on it!",
      "Get Market-Ready: Once approved, you'll receive the final, high-quality, virtually staged images that will make your listing shine.",
      "Don't let an empty property hold you back. Leverage the power of Virtual Staging to boost its visual appeal and market value.",
      "Ready to get started? Add this service to your cart now and take the first step in transforming your property listings.",
      "Send us your images after purchase to wetransfer.photoplan.co.uk with your order number and the style you want us to use. ",
    ],
    label: "Photos",
    number: ["10 Photos"],
    price: ["43.00"],
    images: [
      "/photoplan/1008.webp",
      "/photoplan/1008a.jpg",
      "/photoplan/1008b.jpg",
      "/photoplan/1008c.jpg",
      "/photoplan/1008d.jpg",
      "/photoplan/1008e.jpg",
      "/photoplan/1008f.jpg",
      "/photoplan/1008g.jpg",
      "/photoplan/1008h.jpg",
      "/photoplan/1008i.jpg",
      "/photoplan/1008j.jpg",
      "/photoplan/1008k.jpg",
      "/photoplan/1008l.jpg",
      "/photoplan/1008m.jpg",
      "/photoplan/1008n.jpg",
      "/photoplan/1008o.jpg",
      "/photoplan/1008p.jpg",
      "/photoplan/1008q.jpg",
    ],
  },
  // 9和3一样
  {
    id: 9,
    name: "Property Photography & Floor Plans",
    details: [
      "Capturing Excellence in Every Frame: Property Photography Redefined",
      "At the core of our expertise lies Property Photography, a service we're immensely passionate about. Our unwavering commitment to exceptional quality, combined with our deep understanding of the industry, has established us as a premier choice in the Property Photography sector.",
      "We take great pride in our ability to tailor our Property Photography packages to your specific requirements. Whether you need a stunning exterior shot or a comprehensive day-long photoshoot for a hotel, our approach is flexible and client-centered. Constantly pushing the boundaries, we are on a continuous quest for the latest technology and advanced techniques to ensure we deliver the finest photographic services possible.",
      "Services We Offer:",
      "Property Photography & Floor Plans",
      "Specialised Floor Plan Services Important Information to Note:",
      "Additional travel costs may apply for properties in remote areas, as most of our photographers are based in urban centers. Please contact us before booking to discuss any potential extra travel expenses, ",
    ],
    label: "Photos",
    number: [
      "10 Photos",
      "15 Photos",
      "20 Photos",
      "30 Photos",
      "40 Photos",
      "50 Photos",
    ],
    price: ["200.00", "267.00", "401.00", "535.00", "535.00", "669.00"],
    images: [
      "/photoplan/1009.webp",
      "/photoplan/1003a.jpg",
      "/photoplan/1003b.jpg",
      "/photoplan/1003c.jpg",
      "/photoplan/1003d.jpg",
      "/photoplan/1003e.jpg",
      "/photoplan/1003f.jpg",
      "/photoplan/1003g.jpg",
      "/photoplan/1003h.jpg",
      "/photoplan/1003i.jpg",
      "/photoplan/1003j.jpg",
      "/photoplan/1003k.jpg",
      "/photoplan/1003l.jpg",
      "/photoplan/1003m.jpg",
    ],
  },
  // Executive pens
  {
    id: 10,
    name: "Property Photography + Floor Plan + 360° Virtual Tours",
    details: [
      "Immersive Property Showcases: Professional Photography, Precise Floor Plans & Engaging 360° Virtual Tours",
      "Initially, implementing 360 Virtual Tours for property showcasing took a lot of work, including inadequate filming methods, lacklustre displays, and the looming concern of expensive website modifications. However, Photoplan Bookings has transformed this landscape. We present a fully integrated 360 Virtual Tour solution tailored for Shopify platforms.",
      "Our virtual tours, captured using advanced High Dynamic Range (HDR) photography, are hosted online for effortless access. Clients are provided with a simple URL link effortlessly embeddable into websites, ensuring a seamless integration and enhanced user experience.",
      "For more details and pricing specifically for residential properties, visit Photoplan's 360 Virtual Tours. For inquiries regarding commercial properties, hotels, schools, and more, please get in touch with us at info@photoplan.co.uk.",
    ],
    label: "Number of Photos",
    number: [
      "10 Photos",
      "15 Photos",
      "20 Photos",
      "25 Photos",
      "30 Photos",
      "40 Photos",
      "50 Photos",
    ],
    price: [
      "267.00",
      "334.00",
      "401.00",
      "468.00",
      "535.00",
      "669.00",
      "803.00",
    ],
    images: [
      "/photoplan/10010.webp",
      "/photoplan/10010a.jpg",
      "/photoplan/10010b.jpg",
      "/photoplan/10010c.jpg",
      "/photoplan/10010d.jpg",
      "/photoplan/10010e.jpg",
      "/photoplan/10010f.jpg",
      "/photoplan/10010g.jpg",
      "/photoplan/10010h.jpg",
      "/photoplan/10010i.jpg",
      "/photoplan/10010j.jpg",
      "/photoplan/10010k.jpg",
      "/photoplan/10010l.jpg",
    ],
  },
  {
    id: 11,
    name: "Matterport | Property Photography | Floor Plan",
    details: [
      "Bring your home to life with a Matterport 3D Scan ",
      "Blow prospective buyers away with 3D Showcase – an immersive online 3D experience that gives buyers a true sense of the feeling of your property before they ever see it in person. We put Matterport’s industry-leading technology to work for your listing. Weed out less interested buyers and attract more qualified open house attendees.",
      "https://www.photoplan.co.uk/matterport-3d-vr-scanning/",
      "We scan your property with the Matterport Pro Camera.",
      "Within hours, your virtual Matterport Space is ready to experience.",
      "Anyone can explore with ease right from their browser.",
      "Inside View Navigate a property like a videogame with Inside View.",
      "Dollhouse View Proprietary Dollhouse View shows how an entire property fits together.",
      "Floor Plan View Understand the layout in a glance with a top-down Floor plan View.",
      "Please note these prices are for Residential Properties only for Commercial Property, Hotels, Schools etc please get in touch - info@photoplan.co.uk",
      "Matterpak Files - This additional set of Files includes ",
      "The MatterPak™ Bundle is a set of assets you can download and import into third-party programs. It contains:",
      "Colourized point cloud (.XYZ)",
      "Reflected ceiling plan image:(.JPG) Multiple files if multiple floors,(.PDF) All floors in one file",
      "High-resolution floor plan image. This is not the Schematic Floor Plan. This is similar to the ceiling plan image but of the floor:(.JPG) Multiple files if multiple floors,(.PDF) All floors in one file",
      "3D mesh file (.OBJ), including the necessary texture map image files (.JPG)",
      "These files need to be requested separately cost is £100 ex vat. ",
    ],
    label: "Number of Photos",
    number: [
      "10 Photos",
      "15 Photos",
      "20 Photos",
      "30 Photos",
      "40 Photos",
      "50 Photos",
    ],
    price: ["535.00", "602.00", "669.00", "803.00", "937.00", "1070.00"],
    images: [
      "/photoplan/10011.webp",
      "/photoplan/10011a.jpg",
      "/photoplan/10011b.jpg",
      "/photoplan/10011c.jpg",
      "/photoplan/10011d.png",
      "/photoplan/10011e.png",
      "/photoplan/10011f.jpg",
      "/photoplan/10011g.jpg",
      "/photoplan/10011h.jpg",
      "/photoplan/10011i.jpg",
    ],
  },
  {
    id: 12,
    name: "Property Photography | Residential Property",
    details: [
      "At the heart of our offerings is our distinguished Property Photography service. Our deep-rooted passion for capturing the essence of properties, combined with our technical expertise and industry insights, has positioned us as a frontrunner in Property Photography.",
      "We are known for our adaptable approach, tailoring our services to meet your specific requirements, from a single, striking exterior shot to an extensive, full-day photoshoot of more significant properties like hotels. Our commitment to innovation drives us to continually explore emerging technologies and advanced techniques, ensuring we deliver top-tier photographic solutions.",
      "For more information, visit our website: Photoplan Property Photography",
      "Please Note:For properties located in remote areas, additional travel expenses may apply. We recommend contacting us before booking, as our photographers are predominantly based in urban areas. This ensures clarity on any extra travel costs.",
    ],
    label: "Number of Photos",
    number: [
      "10 Photos",
      "15 Photos",
      "20 Photos",
      "30 Photos",
      "40 Photos",
      "50 Photos",
    ],
    price: ["133.00", "167.00", "200.00", "267.00", "334.00", "401.00"],
    images: [
      "/photoplan/10012.avif",
      "/photoplan/10012a.jpg",
      "/photoplan/10012b.jpg",
      "/photoplan/10012c.jpg",
      "/photoplan/10012d.jpg",
      "/photoplan/10012e.jpg",
      "/photoplan/10012f.jpg",
      "/photoplan/10012g.jpg",
      "/photoplan/10012h.jpg",
      "/photoplan/10012i.jpg",
      "/photoplan/10012j.jpg",
      "/photoplan/10012k.jpg",
      "/photoplan/10012l.jpg",
      "/photoplan/10012m.jpg",
    ],
  },
];

export default productData;
