import React from "react";
import "../css/footer.css";
import { Link } from "react-router-dom";

function Footer() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="bottom-container">
        <div className="left__right">
          <div className="botRight">
            <div className="footer__column">
              <Link href="/" className="footer__column__header">
                Contact us
              </Link>
              <div className="footer__column__content">Photoplan.co.uk</div>
            </div>
          </div>
          <div className="contact-link">
            <Link
              to="tel:01790729833"
              className="footer__links_list__item footer__links_list__item--contact"
            >
              <img
                width="24"
                height="24"
                src="https://img.icons8.com/forma-light-filled/24/608daf/phone.png"
                alt="phone"
              />
              01790 729 833
            </Link>
            <Link
              to="tel:01790 755 833"
              className="footer__links_list__item footer__links_list__item--contact"
            >
              <img
                width="24"
                height="24"
                src="https://img.icons8.com/forma-light-filled/24/608daf/phone.png"
                alt="phone"
              />
              01790 755 833
            </Link>
            <Link
              to="mailto:number@soliress.xyz"
              className="footer__links_list__item footer__links_list__item--contact"
            >
              <img
                width="20"
                height="20"
                src="https://img.icons8.com/ios-filled/50/608daf/paper-plane.png"
                alt="paper-plane"
              />
              soliress.xyz
            </Link>
          </div>
          <div style={{ display: "grid" }}>
            <Link
              to="phone:"
              className="footer__links_list__item--contact footer__links_list__item11"
            >
              Phone lines open:
            </Link>
            <span className="footer__links_list__item--contact__label">
              8am-5pm, Monday-Thursday
              <br />
              8am-4pm, Friday
              <br />
              Closed weekends and bank holidays.
            </span>
          </div>
        </div>

        <div className="legal-menu">
          <div className="legal-menu-left">
            <span className="legal-menu__item">
              <Link className="legal-menu__link" to="/about" onClick={toTop}>
                About Us
              </Link>
            </span>
            <span className="legal-menu__item">
              <Link className="legal-menu__link" to="/privacy" onClick={toTop}>
                Privacy policy
              </Link>
            </span>
            <span className="legal-menu__item">
              <Link className="legal-menu__link" to="/terms" onClick={toTop}>
                Terms of service
              </Link>
            </span>
            <span className="legal-menu__item">
              <Link className="legal-menu__link" to="/refund" onClick={toTop}>
                Refund policy
              </Link>
            </span>
          </div>
          <div className="legal-menu-right">
            <span className="legal-menu__item">Copyright © 2024</span>
            <span className="legal-menu__item">
              Ben Gutierrez - Real Estate Marketing. Website builder by Shopify
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
