import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/contactus.css";

function About() {
  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        <div className="mainBox1" style={{ padding: "0 28%" }}>
          <div className="container-fluid">
            <div className="signpost1">
              <h1 style={{ textAlign: "center", fontSize: "41px" }}>
                About Us
              </h1>
              <p>
                From its inception, Photoplan Bookings embarked on a mission to
                redefine the property sector through exemplary Property
                Photography and RICS-certified Floor Plans. Our foundational
                services have always included Property Photography, officially
                approved Floor Plans by RICS, and captivating 360° virtual
                experiences.
              </p>
              <p>
                For two decades, we've built a formidable reputation in crafting
                top-tier property marketing assets. Our unwavering commitment
                and intense zeal have elevated us to become one of the foremost
                property marketing agencies in the UK.
              </p>
              <p>
                In line with the shifting dynamics of the property industry,
                we've constantly enriched our suite of services. Our extensive
                range now covers the complete gamut of property marketing
                solutions: from high-grade Property Photography and intricate
                floor plans to interactive floor layouts, absorbing 360-degree
                virtual experiences, tailor-made Property Brochures,
                Architectural Renderings, Land Registry-compliant Lease Plans,
                and Energy Performance Certificates (EPCs).
              </p>
              <p>
                Operating as an all-encompassing property marketing firm, our
                goal is to serve as your go-to hub for all marketing essentials.
                A single email or phone call to us activates our full array of
                services; we manage the rest. Forget about coordinating with
                multiple vendors; we aim to fulfill all your needs in one
                property visit, thereby saving you valuable time and resources.
              </p>
              <p>
                What differentiates us is our outstanding team—comprising not
                just 'Photoplanners' but also graphic and web design experts,
                Domestic Energy Assessors (DEAs), 3D creative artists, CAD
                professionals, administrative personnel, and account managers.
                Their collaborative expertise forms the backbone of our
                unwavering dedication to peerless service.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default About;
