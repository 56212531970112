import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import productData from "../Datas/productData";
import Header from "./Header";
import Footer from "./Footer";
import "../css/productdetails.css";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../Component/CartContext";

const ProductDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const product = productData.find((item) => item.id === parseInt(id));
  const { addToCart } = useContext(CartContext);
  const [number, setNumber] = useState(product?.number[0] || "");
  const [price, setPrice] = useState(product?.price[0] || "");

  useEffect(() => {
    if (product && number) {
      const index = product.number.indexOf(number);
      setPrice(product.price[index]);
    }
  }, [number, product]);

  const handleAddToCart = (id) => {
    if (!number) {
      alert("Please select a color!");
      return;
    }
    addToCart(id, 1, number, price);
    navigate("/cartpage");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        <div className="mainBox1" style={{ padding: "5% 13%" }}>
          <div className="container-fluid">
            <div className="item__firstImg">
              <img alt="" src={product.images[0]} />
            </div>
            <div className="item__details">
              <span
                className="product__vendor text-small text-center"
                itemprop="brand"
              >
                Photoplan
              </span>
              <h1>{product.name}</h1>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <span className="currency-converter-amount-box">
                  <img
                    src="https://img.icons8.com/emoji/48/us-outlying-islands-emoji.png"
                    alt="us-outlying-islands-emoji"
                    style={{ width: "22px", height: "22px" }}
                  />
                  &nbsp; ${price}
                </span>
              </div>
              <div className="num__des">
                <div style={{ paddingTop: "1.5%" }}>
                  <div className="product-form__item supports-js">
                    <label
                      className="single-option-selector__label"
                      for="Number of Images"
                    >
                      {product.label}
                    </label>

                    <select
                      id="Number of Images"
                      name="Number of Images"
                      className="single-option-selector"
                      value={number}
                      onChange={(e) => setNumber(e.target.value)}
                    >
                      {product.number.map((num, index) => (
                        <option value={num} selected="" key={index}>
                          {num}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <button
                      className="add-to-cart"
                      onClick={() => handleAddToCart(product.id)}
                    >
                      Add to Cart
                    </button>
                  </div>
                </div>
                <div className="item__des">
                  {product.details.map((des, index) => (
                    <p key={index}>{des}</p>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="img__show">
          {product.images.map((image, index) => (
            <img key={index} alt="" src={image} />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductDetail;
